<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title"></div>   
        <div class="rb1" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>   
      </div>
      <div class="content">
        <div class="items">  
          <!--<div class="item"> 
            <div class="box qq">
              <div class="name">   
                <select class="form_select1 le" v-model="selectItem" @change="selectZt($event)">
                    <option v-for="ztitem in ztitems" :key="ztitem.id" :value="ztitem.id">{{ztitem.name}}</option>
                </select> 
                <select class="form_select" v-model="selectQyItem" @change="selectQy($event)">
                    <option v-for="qyitem in qyitems" :key="qyitem.zone_code" :value="qyitem.zone_code">{{qyitem.zone_name}}</option>
                </select>                          
              </div>           
            </div>           
          </div>
          <div class="item"> 
            <div class="box qq">
              <div class="name">   
                <select class="form_select1" v-model="selectItem" @change="selectZt($event)">
                    <option v-for="ztitem in ztitems" :key="ztitem.id" :value="ztitem.id">{{ztitem.name}}</option>
                </select> 
                <select class="form_select ri" v-model="selectQyItem" @change="selectQy($event)">
                    <option v-for="qyitem in qyitems" :key="qyitem.zone_code" :value="qyitem.zone_code">{{qyitem.zone_name}}</option>
                </select>                          
              </div>           
            </div>           
          </div>-->
          <div class="item">
            <div class="box qq">
              <div class="name">
                <input type="text" v-model="site_name" class="input qy" placeholder="查询场所名称" />
                <div class="rb2" @click="query">
                  <img src="@/assets/cx.png" class="icon" />
                  <div class="text">查询</div>
                </div>                               
              </div>           
            </div>          
          </div>      
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.site_code">
            <div class="box zz" @click="onChoosePlace(item)">
              <div class="name">
                <span class="roomcode">{{ item.site_name }}</span>
              </div>
            </div>
            <div class="box yy2" @click="onChoosePlace(item)">
              <div class="name">                              
                <img src="@/assets/arrow.png" class="arrow" />         
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    /*this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;*/
    this.query();
  },
  data() {
    return {
      site_name: '',
      site_code: '',
      items: [
        /*{site_code:'1', site_name: '英图网咖'},
        {site_code:'2', site_name: '大地网络竞技宾馆'},
        {site_code:'3', site_name: '无名氏网吧'}*/
      ]
    };
  },
  methods: {
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_cg_listwb6',
        qs.stringify({
          name: this.site_name
        })
      );
      if (data.flag === '0') { 
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      }else{
        Toast(data.message);
      }
    },
    goback() {
      this.$router.replace('/user/my'); 
    },
    onChoosePlace(item) {
      this.$router.replace({path:'/maintain/modify_info', query:{site_code: item.site_code}});
    }
    
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 30px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 90%;
    }
    &.yy {
      width: 20%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 30%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 76px;
      overflow: hidden;
      font-size: 32px;
      .arrow {
        float: right;
        display: block;
        margin-top: 17px;
        //margin-right: 38px;
        height: 40px;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(184, 188, 190);
        margin-right: 20px;
        &.na {
          float: left;
          margin-left: 20px;
        }
      }
      .roomcode {
        font-size: 28px;
        //margin-right: 20px;
      }
      .region {
        font-size: 26px;
        color: rgb(184, 188, 190);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 469px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 60px;
        width: 125px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 16px;
        margin-left: 10px;
        border-radius: 15px;
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 60px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 346px center no-repeat;
        background-size: 26px;
        width: 380px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        direction: rtl;
        padding-right: 60px;
        &.ri {
          //margin-right: 20px;
          float: left;
        }
      }
      select:focus { outline: none; }

      .form_select1 {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
        &.le {
          float: left;
        }
      }
      select:focus { outline: none; }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
